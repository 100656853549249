// bootstrapp components
import * as bootstrap from 'bootstrap';
import Alert from 'bootstrap/js/dist/alert'

// jquery components
import $ from "jquery";

// ScrollMagic components
import * as ScrollMagic from "scrollmagic"; 
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

// Gsap components
import gsap from "gsap";
import Flip from "gsap/Flip";
import { TweenMax, TimelineMax, timeline, from } from "gsap"; // Also works with TweenLite and TimelineLite
import { Timeline } from 'gsap/gsap-core';
import { Power3 } from 'gsap/src/all';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

var controller = new ScrollMagic.Controller();

///////////////////
// HOME
///////////////////

var tl1 = new Timeline()
  .from("#intro", {duration:2, scale: 1.2, opacity: 0 })
  .from("#circlesIcon .example", { opacity:0, y:200, stagger:0.5, duration:0.8, ease:"back"})
  .from("#logo", {xPercent: -120, opacity: 0, duration:0.2})
  .from("#mouse", {y: 50, opacity: 0, duration:0.});

///////////////////////////
// frahgata and clouds section intro
///////////////////////////
// fragatas
var fragatasFlights = new TimelineMax({repeat: -1})
.add(TweenMax.to("#intro .fragata-1", 70, {x:1440, y:-300}));

// fragata movement
new ScrollMagic.Scene({
    //duration: 1200
    //offset: -100
})
.setTween(fragatasFlights)
.addTo(controller);

///////////////////
// Clouds
///////////////////
var clouds = document.querySelectorAll('.stCloud');

for (let i = 0; i < clouds.length; i++) {
  let data = clouds[i].getBBox();
  gsap.fromTo(
      clouds[i],
      { x: 1702.41 - data.x },
      {
        duration: gsap.utils.random(75, 105),
        x: 0 - data.x - data.width,
        repeat: -1,
        ease: 'none'
      }
    )
    .progress(Math.random());
}
//gsap.to('svg', 1, { opacity: 1, ease: 'none'});

///////////////////
/// jellyfish ///
///////////////////
  // build tween
  var tween = TweenMax.to("#jellyfish", 0.7, {scale: 1.1, repeat: -1, yoyo: true});
  var tween = TweenMax.to("#jellyfish", 20, {x: 35, y: 150, repeat: -1, yoyo: true});

  var tween = new ScrollMagic.Scene({
    triggerElement: "#footer", 
    triggerHook: 0.95
    })
    .setTween(tween)
    .addTo(controller);

///////////////////
/// diving ///
///////////////////

var tween = TweenMax.fromTo( "#diving",
      { x: 0, y: 0 },
      { x: -60 , y: 1100 }
    );

var scene = new ScrollMagic.Scene({
  triggerElement: "#live", 
  duration: "350%",
  triggerHook: 0.95
  })
  .setTween(tween)
  .addTo(controller);


///////////////////
/// sealion ///
///////////////////

  var tween = TweenMax.to("#sealion", { 
    keyframes: {
      "25%": { x: 100, scale: 1.7 },
      "50%": { y: 100, ease: 'power3.in' },
      "75%": { x: 0},
      "100%": { y: 0 },
      easeEach: 'none',
      ease: 'power3.out'
    },
    rotate: 30,
    ease: 'elastic.in',
    duration: '4',
    transformOrigin: 'center'

  });

  var scene = new ScrollMagic.Scene({triggerElement: "#contact", duration: "100%"})
  .setTween(tween)
  .addTo(controller);

///////////////////
/// Cardbox ///
///////////////////

var tween = TweenMax.to( ".cardbox", {
  keyframes:  {
    y: [0, -70],
    ease: "none",
    easeEach: "power3.inOut"
  },
  rotate: 0,
  ease: "elastic",
  duration: 3,
  stagger: 0.5
});

var scene = new ScrollMagic.Scene({
    triggerElement: "#lend",
    triggerHook: 0
    //duration: "100%"
  })
.setTween(tween)
.addTo(controller);

 ///////////////////
 /// fade in for .photo class
 ///////////////////

 $(".photo").each(function() {

  console.log(this);

  //build scene
  var scene = new ScrollMagic.Scene({
    triggerElement: this.children[0],
    //duration: '90%',
    triggerHook: 0.9
  })
  .setClassToggle (this , 'fade-in' ) //add class fade-in
  .addTo(controller);
  
});

///////////////////
 /// fade in for p.lead class
 ///////////////////

 $(".fadeText").each(function() {

  console.log(this);

  //build scene
  var scene = new ScrollMagic.Scene({
    triggerElement: this.children[0],
    //duration: '90%',
    triggerHook: 0.9
  })
  .setClassToggle (this, 'fade-in' ) //add class fade-in
  .addTo(controller);
  
});

//////////////////////
// gallery
//////////////////////

gsap.registerPlugin(Flip);

const items = gsap.utils.toArray(".item"),
      details = document.querySelector('.detail'),
      detailContent = document.querySelector('.content'),
      detailImage = document.querySelector('.detail img'),
      detailTitle = document.querySelector('.detail .title'),
      detailSecondary = document.querySelector('.detail .secondary'),
      detailDescription = document.querySelector('.detail .description');

let activeItem; // keeps track of which item is open (details)

gsap.set(detailContent, { yPercent: -100 }); // close the details "drawer" (content) initially

function showDetails(item) {
	if (activeItem) { // someone could click on an element behind the open details panel in which case we should just close it.
		return hideDetails();
	}
	let onLoad = () => {

		// position the details on top of the item (scaled down)
		Flip.fit(details, item, {scale: true, fitChild: detailImage});

		// record the state
		const state = Flip.getState(details);

		// set the final state
		gsap.set(details, {clearProps: true}); // wipe out all inline stuff so it's in the native state (not scaled)
		gsap.set(details, {xPercent: -50, top: "50%", yPercent: -50, visibility: "visible", overflow: "hidden"});

		
    Flip.from(state, {
			duration: 0.5,
			ease: "power2.inOut",
			scale: true,
			onComplete: () => gsap.set(details, {overflow: "auto"}) // to permit scrolling if necessary
		})
			// Flip.from() returns a timeline, so add a tween to reveal the detail content. That way, if the flip gets interrupted and forced to completion & killed, this does too.
			.to(detailContent, {yPercent: 0}, 0.2);

		detailImage.removeEventListener("load", onLoad);
		document.addEventListener('click', hideDetails);
	};

	// Change image and text
	const data = item.dataset;
	detailImage.addEventListener("load", onLoad);
	detailImage.src = item.querySelector('img').src;
	detailTitle.innerText = data.title;
	detailSecondary.innerText = data.secondary;
	detailDescription.innerText = data.text;

	// stagger-fade the items out from the one that was selected in a staggered way (and kill the tween of the selected item)
	gsap.to(items, {opacity: 0.3, stagger: { amount: 0.1, from: items.indexOf(item), grid: "auto"}}).kill(item);
	gsap.to(".app", {duration: 1, delay: 0.3}); // fade out the background
	activeItem = item;
}

function hideDetails() {
	document.removeEventListener('click', hideDetails);
	gsap.set(details, {overflow: "hidden"});

	// record the current state of details
	const state = Flip.getState(details);

	// scale details down so that its detailImage fits exactly on top of activeItem
	Flip.fit(details, activeItem, {scale: true, fitChild: detailImage});

	// animate the other elements, like all fade all items back up to full opacity, slide the detailContent away, and tween the background color to white.
	const tl = gsap.timeline();
	tl.set(details, {overflow: "hidden"})
    .to(detailContent, {yPercent: -100})
	  .to(items, {opacity: 1, stagger: {amount: 0.7, from: items.indexOf(activeItem), grid: "auto"}});
	  //.to(".app", {backgroundColor: "none"}, "<");

	// animate from the original state to the current one.
	Flip.from(state, {
		scale: true,
		duration: 0.5,
    oppacity: 0,
		delay: 0.2, // 0.2 seconds because we want the details to slide up first, then flip.
		onInterrupt: () => tl.kill()
	})
	  .set(details, {visibility: "hidden"});

	activeItem = null;
}

// Add click listeners
gsap.utils.toArray('.item').forEach(item => item.addEventListener('click', () => showDetails(item)));